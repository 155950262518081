<template>
  <div>
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Nạp tiền tài khoản mua vé</div>
      <div class="header-link">
        <router-link to="/tai-khoan/lich-su-nap-tien/"
          ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <g transform="translate(-1095 -280)">
              <rect width="12" height="12" transform="translate(1095 280)" fill="#fff" opacity="0" />
              <path
                d="M.152-4.632a.45.45,0,0,0,0,.637l5.5,5.549a.45.45,0,0,0,.637,0L7.036.812a.45.45,0,0,0,0-.637L2.593-4.313,7.036-8.8a.45.45,0,0,0,0-.637l-.742-.742a.45.45,0,0,0-.637,0Z"
                transform="translate(1096.98 290.313)"
                fill="#666"
              />
            </g>
          </svg>
          Lịch sử nạp tiền</router-link
        >
      </div>
    </div>
    <hr class="d-none d-lg-block" />
    <div class="section-content">
      <b-card class="content-card" v-if="!isLoading">
        <div class="notification-message" v-if="paymentDetail.status == 2">
          <img src="@/assets/img/icon/icon_result-fail.svg" alt="" />
          <p class="danger-message">Nạp tiền thất bại!</p>
          <p class="response-message">{{ paymentDetail.message || '' }}</p>
        </div>
        <div class="notification-message" v-else-if="paymentDetail.status == 1">
          <img src="@/assets/img/icon/icon_result-success.svg" alt="" />
          <p class="success-message">
            <span v-if="paymentDetail.method_name === 'Hoàn tiền'">Hoàn tiền thành công!</span>
            <span v-else>Nạp tiền thành công!</span>
          </p>
        </div>
        <div class="notification-message" v-else-if="paymentDetail.status == 0">
          <img src="@/assets/img/icon/icon_result-pending.svg" alt="" />
          <p class="warning-message">Giao dịch đang xử lý!</p>
        </div>
        <div class="payment-info-container">
          <div class="row payment-row">
            <div class="col-md-3">
              <div class="payment-title">MÃ GIAO DỊCH</div>
              <div class="payment-detail">#{{ paymentDetail.display_deposit_id }}</div>
            </div>
            <div class="col-md-3">
              <div class="payment-title">THỜI GIAN</div>
              <div class="payment-detail">
                {{
                  getTimeZone(paymentDetail.date)
                    .split(' ')
                    .reverse()
                    .join(' ')
                }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="payment-title">HÌNH THỨC</div>
              <div class="payment-detail">{{ paymentDetail.method_name }}</div>
            </div>
            <div class="col-md-3">
              <div class="payment-title">SỐ TIỀN NẠP</div>
              <div class="payment-detail">
                {{ formatPrice(paymentDetail.value) }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="payment-title">PHÍ GIAO DỊCH</div>
              <div class="payment-detail">
                {{ formatPrice(paymentDetail.fee) }}
              </div>
            </div>
            <div class="col-md-3" v-if="paymentDetail.commission > 0">
              <div class="payment-title">HOA HỒNG</div>
              <div class="payment-detail">
                {{ formatPrice(paymentDetail.commission) }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="payment-title">TỔNG THANH TOÁN</div>
              <div class="payment-detail">
                {{ formatPrice(paymentDetail.total) }}
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <Spinner v-else />
    </div>
  </div>
</template>

<script>
import PaymentService from '@/api/payment';
import Spinner from '@/components/Spinner.vue';

export default {
  layouts: 'Deposit',
  components: {
    Spinner
  },

  watch: {
    $route(to, from) {
      if (to.params.id != from.params.id) {
        this.getPaymentResult(to.params.id);
        this.refreshBalances();
      }
    }
  },
  data() {
    return {
      isLoading: true,
      paymentDetail: {
        status: 1,
        display_deposit_id: '',
        date: '',
        method_name: '',
        value: '',
        fee: '',
        total: '',
        message: ''
      }
    };
  },
  beforeCreate() {},
  created() {
    this.getPaymentResult(this.$route.params.id);
    this.refreshBalances();
  },
  mounted() {},
  methods: {
    getPaymentResult(id) {
      PaymentService.getDepositResult(id)
        .then((res) => {
          if (res.data.status == true) {
            this.paymentDetail = { ...res.data.data };
          }
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
        });
    }
  },
  computed: {
    accountId() {
      return this.$store.getters.getterAccountId;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to?.meta?.redirectBack !== '/tai-khoan/nap-tien/') {
      this.$store.commit('setSavePagination', null);
    }
    next();
  }
};
</script>
